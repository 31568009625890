* {
  margin: 0;
  padding: 0;
}

body {
  font:
    400 13pt / 1.6 'Montserrat',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222;

  @media (max-width: 1024px) {
    font-size: 11pt;
  }
}

a {
  color: #1c71b4;
  text-decoration: none;
  display: inline-block;
  transition: all 200ms ease-out;
}

a:hover {
  color: #0d4470;
}

main {
  padding: 100px 120px;
  max-width: 1600px;
  margin: 0 auto;

  @media (max-width: 1250px) {
    padding: 50px 70px;
  }

  @media (max-width: 1024px) {
    padding: 15px;
  }
}

section {
  margin-bottom: 100px;

  @media (max-width: 1024px) {
    margin-bottom: 50px;
  }
}

p {
  margin-bottom: 20px;
}

h2 {
  font-size: 40pt;
  text-align: center;
  margin-bottom: 50px;

  @media (max-width: 1024px) {
    font-size: 30pt;
  }
}

.header {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content min-content 1fr;
  column-gap: 50px;

  @media (max-width: 1024px) {
    column-gap: 20px;
  }

  .photo {
    grid-row: 1 / span 2;
    grid-column: 1 / span 1;

    img {
      display: block;
      width: 300px;
      height: 300px;
      border-radius: 5%;
      overflow: hidden;
      margin-bottom: 30px;

      @media (max-width: 1024px) {
        width: 25vw;
        height: 25vw;
      }
    }
  }

  .name {
    grid-row: 1 / span 1;
    grid-column: 2 / span 1;

    h1 {
      font-size: 55pt;
      line-height: 0.8;
      margin-bottom: 15px;

      @media (max-width: 1024px) {
        font-size: 26pt;
        line-height: 0.9;
      }
    }

    .subtitle {
      font-size: 25pt;
      line-height: 1.3;
      margin-bottom: 40px;

      span {
        white-space: nowrap;
      }

      @media (max-width: 1024px) {
        font-size: 16pt;
        margin-bottom: 20px;
        line-height: 1.4;
      }
    }
  }

  .contact {
    grid-row: 3 / span 1;
    grid-column: 1 / span 1;
    padding-left: 30px;
    color: #666;
    font-size: 15pt;

    p {
      margin-bottom: 15px;

      @media (max-width: 1024px) {
        margin-bottom: 5px;
      }
    }

    svg {
      width: 20px !important;
      margin-right: 10px;
    }

    @media (max-width: 1024px) {
      /* On mobile, contact info appears beneath the title */
      grid-row: 2 / span 1;
      grid-column: 2 / span 1;
      font-size: 12pt;
      padding-left: 0;
      margin-bottom: 30px;
    }
  }

  .intro {
    grid-row: 2 / span 2;
    grid-column: 2 / span 1;

    @media (max-width: 1024px) {
      /* On mobile, intro text appears beneath the image, full-width */
      grid-row: 3 / span 1;
      grid-column: 1 / span 2;
    }
  }
}
